import { useAlternateLinks, useHead, useRoute } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import type { ModelComparisonCompareModels } from '@backmarket/http-api/src/api-specs-model-comparison'

import { getMetas } from '~/utils/seo/meta'

export function useModelsComparisonHead(
  content: MaybeRefOrGetter<
    ModelComparisonCompareModels['content'] | undefined
  >,
) {
  const { getAlternateLinks } = useAlternateLinks()
  const route = useRoute()
  const alternateLinks = getAlternateLinks({
    link: route.path,
  })

  const { customContent } = toValue(content) || {}

  useHead({
    title: customContent?.title,
    link: alternateLinks,
    meta: getMetas({
      title: customContent?.metaTitle || '',
      description: customContent?.metaDescription || '',
    }),
  })
}
