<template>
  <div
    class="mt-56 flex flex-col items-center md:col-span-4 md:flex-row md:justify-center lg:col-span-8 lg:col-start-12"
  >
    <InputModelsAutocomplete
      id="models-autocomplete-0"
      :autocomplete-config="autocompleteConfig"
      :initial-model-name="modelsNames?.[0]"
      :selected-models="selectedModelsNames"
      @model-selected="(modelName) => select({ modelName, position: 0 })"
      @model-unselected="() => unselect({ position: 0 })"
    />
    <div class="heading-1 m-auto md:mx-8">
      {{ i18n(translations.vs) }}
    </div>
    <InputModelsAutocomplete
      id="models-autocomplete-1"
      :autocomplete-config="autocompleteConfig"
      :initial-model-name="modelsNames?.[1]"
      :selected-models="selectedModelsNames"
      @model-selected="(modelName) => select({ modelName, position: 1 })"
      @model-unselected="() => unselect({ position: 1 })"
    />
  </div>
</template>

<script setup lang="ts">
import { type MultipleQueriesQuery } from '@algolia/client-search'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useSelectModels } from '../../composables/useSelectModels'
import type { SelectedModels } from '../../models/selected-models'
import InputModelsAutocomplete from '../InputsModelAutocomplete/InputsModelAutocomplete.vue'

import translations from './ComparisonForm.translations'

const props = defineProps<{
  autocompleteConfig: {
    apiKey: string
    queries: MultipleQueriesQuery[]
  }
  modelsNames?: SelectedModels
}>()

const i18n = useI18n()

const { select, selectedModelsNames, unselect } = useSelectModels({
  initialModels: props.modelsNames,
})
</script>
