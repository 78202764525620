import { type MaybeRefOrGetter, toValue } from 'vue'

import type { MultipleQueriesQuery } from '@algolia/client-search'

import {
  type InitializableAlgoliaClient,
  useAlgolia,
  useAlgoliaWithDeps,
} from '~/scopes/cms/products/composables/useAlgolia'

import type { AlgoliaComparableModelsIndexHit } from '../utils/algolia/comparable-model-algolia-hit'

function toArray<T>(stringOrArray: string | readonly T[]) {
  if (!stringOrArray) return []
  if (typeof stringOrArray === 'string') return [stringOrArray]

  return stringOrArray
}

/**
 *
 * @param initializableAlgoliaClient optional param to inject a fake "Algolia" when testing.
 * Only pass something if you do not want to use the actual algoliasearch library
 * @returns
 */
export function useSearchModelsToCompare(deps: {
  initializableAlgoliaClient?: InitializableAlgoliaClient<AlgoliaComparableModelsIndexHit>
  selectedModelsNames: MaybeRefOrGetter<string[]>
  autocompleteConfig: { apiKey: string; queries: MultipleQueriesQuery[] }
}) {
  const {
    initializableAlgoliaClient,
    autocompleteConfig: { queries, apiKey },
    selectedModelsNames,
  } = deps
  const { search: basicSearch } = initializableAlgoliaClient
    ? useAlgoliaWithDeps({
        initializableAlgoliaClient,
      })
    : useAlgolia<AlgoliaComparableModelsIndexHit>()

  async function searchModelsNotSelected(query: string) {
    const selectedModelsFacetFilters = toValue(selectedModelsNames).map(
      (modelName) => `model.name:-${modelName}`,
    )
    const ignoreSelectedModelsQueries = queries.map((q) => {
      const queryFaceFiltersArr = toArray(q.params?.facetFilters || [])

      const facetFilters = [
        ...queryFaceFiltersArr,
        ...selectedModelsFacetFilters,
      ]

      return {
        ...q,
        params: {
          ...q.params,
          facetFilters,
          query,
        },
      }
    })

    const { results } = await basicSearch({
      apiKey,
      queries: ignoreSelectedModelsQueries,
    })

    return results.flatMap((r) =>
      r.hits.map((h) => ({ name: h.model.name, id: h.objectID })),
    )
  }

  return { searchModels: searchModelsNotSelected }
}
