<template>
  <div class="flex flex-col items-center">
    <div class="max-w-full grow lg:w-[1120px] lg:grow-0">
      <div class="grid-standard px-24 pb-56 md:px-32 md:pb-72">
        <div
          v-if="modelsCharacteristics.length > 0"
          class="my-32 flex min-w-0 justify-center gap-x-[32px] md:col-span-4 lg:col-span-8 lg:col-start-3"
        >
          <div
            v-for="(model, index) in modelsNames"
            :key="model"
            class="w-1/2 grow md:max-w-[352px]"
          >
            <ModelPreview
              :image="modelsCharacteristics[index].image || ''"
              :link="modelsCharacteristics[index].productPageLink"
              :model-name="model"
              :price="modelsCharacteristics[index].price"
            />
          </div>
        </div>

        <div
          class="mt-32 flex flex-col gap-32 md:col-span-4 md:mt-72 md:gap-56 lg:col-span-8 lg:col-start-3"
        >
          <AsyncSpiderChart :chart-data="chartData" />

          <Table
            v-for="(table, index) in comparisonTables"
            :key="index"
            :data="table"
            :models="modelsNames"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useChartData } from '../../composables/useChartData'
import type { ModelCharacteristics } from '../../models/model-characteristics'
import { mapModelCharacteristicsListToComparisonTables } from '../../utils/table/comparisonTablesAdapter'
import AsyncSpiderChart from '../SpiderChart/AsyncSpiderChart.vue'
import Table from '../Table/Table.vue'

import ModelPreview from './components/ModelPreview.vue'

const props = defineProps<{
  modelsNames: string[]
  modelsCharacteristics: ModelCharacteristics[]
}>()

const chartData = useChartData(() => props.modelsCharacteristics)

const i18n = useI18n()
const comparisonTables = computed(() =>
  mapModelCharacteristicsListToComparisonTables(props.modelsCharacteristics, {
    i18n,
  }),
)
</script>
