<template>
  <NuxtErrorBoundary>
    <ClientOnly>
      <Suspense>
        <template #default>
          <div>
            <SpiderChart :chart-data="chartData" />
          </div>
        </template>
        <template #fallback>
          <RevSkeleton
            class="!h-380 w-[690px] max-w-full md:!h-[460px]"
            shape="rectangle"
          />
        </template>
      </Suspense>

      <template #fallback>
        <RevSkeleton
          class="!h-380 w-[690px] max-w-full md:!h-[460px]"
          shape="rectangle"
        />
      </template>
    </ClientOnly>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { RevSkeleton } from '@ds/components/Skeleton'

import type { ChartData } from './types'

const SpiderChart = defineAsyncComponent(() => import('./SpiderChart.vue'))

defineProps<{ chartData: ChartData }>()
</script>
